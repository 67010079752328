import React from "react";
import UserLayout from "../../../layout/userLayout";
import { Form, Button, Row, Col } from "react-bootstrap";
import appIcon from "../../../assets/icons/appLogo.png";
import { useHistory } from "react-router-dom";

const SignUp = () => {
  const history = useHistory();
  return (
    <UserLayout>
      <div className="login-card">
        <div className="text-center">
          <img src={appIcon} width="80px" alt="logo" />
        </div>
        <h2 className="auth-title">Sign Up</h2>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label className="login-label">Email address</Form.Label>
            <Form.Control
              className="app-input login-input"
              type="email"
              placeholder="example@email.com"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="login-label">Password</Form.Label>
            <Form.Control
              className="app-input login-input"
              type="password"
              placeholder="Password"
            />
          </Form.Group>

          <div>
            <Button className="mt-4 auth-btn" variant="primary" type="submit">
              Sign Up
            </Button>
          </div>
        </Form>
        <Row xs="auto" className="mt-4">
          <Col>Already have account ?</Col>
          <Col>
            <button
              className="btn btn-blank mb-3 register-link"
              onClick={() => history.push("/user/login")}
            >
              Login
            </button>
          </Col>
        </Row>
      </div>
    </UserLayout>
  );
};

export default SignUp;
