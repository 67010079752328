import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { userLogin } from "../../../api";
import { setAuthToken } from "../../../api/Utils";
import { setCurrentUser, setToken } from "../../../helpers/Utils";

const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onChangeEmail = useCallback((e) => {
    setError(false);
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e) => {
    setError(false);
    setPassword(e.target.value);
  }, []);

  const onSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const user = {
      email,
      password,
    };
    userLogin(user).then((res) => {
      if (res) {
        if (res.error) {
          setLoading(false);
          setError(true);
        } else {
          setLoading(false);
          setAuthToken(res.token);
          setToken(res.token);
          setCurrentUser(user);
          history.push("/app");
        }
      }
    });
  };

  return {
    loading,
    email,
    password,
    error,
    onChangeEmail,
    onChangePassword,
    onSubmit,
  };
};

export default useLogin;
