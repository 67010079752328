import axios from "axios";
import { API_HEADER, BASE_URL } from "./Config";

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  headers: API_HEADER,
});

const getData = async (url) => {
  try {
    const res = await api.get(url);
    if (res.data.status === "Success" || 200) {
      return res.data;
    } else {
      alert("Error", res.data[0]);
    }
  } catch (error) {
    if (error.response) {
      // console.log("Res Error", error.response);
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      alert(error.response.data);
      alert(error.response.status);
      alert(error.response.headers);
    } else if (error.request) {
      // console.log("Req Error", error.request);

      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      alert(error.request);
    } else if (error.message) {
      // Something happened in setting up the request that triggered an Error
      alert(error.message);
    } else {
      alert(error);
    }
    // console.log(error.config);
  }
};

const postData = async (url, body) => {
  try {
    const res = await api.post(url, body);
    if (res.status === 200) {
      return res.data;
    } else {
      alert("Error", res.data[0]);
    }
  } catch (error) {
    if (error.response) {
      // console.log("Res Error", error.response);

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      alert(error.response.data);
      alert(error.response.status);
      alert(error.response.headers);
    } else if (error.request) {
      // console.log("Req Error", error.request);

      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      alert(error.request);
    } else if (error.message) {
      // Something happened in setting up the request that triggered an Error
      alert("Error", error.message);
    } else {
      alert(error);
    }
    // console.log(error.config);
  }
};

const setAuthToken = (token = null) => {
  api.defaults.headers.common["auth-token"] = token;
};

// api.interceptors.request.use(
//   function (config) {
//     console.log("Config", config);
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

export { getData, postData, setAuthToken };
