const Urls = {
  loginUrl: "validateAuth",
  addContractor: "addContractor",
  getContractors: "getContractors",
  getContractorDetailsById: "getContractorDetails",
  updateContractor: "editContractor",
  addClient: "addClient",
  getClients: "getClients",
  getClientDetailsById: "getClientDetails",
  updateClient: "editClient",
  getWorks: "getWorks",
  getContractorNames: "getContractorNames",
  getClientNames: "getClientNames",
  deleteContractor: "deleteContractor",
  deleteClient: "deleteClient",
  getServices: "getServices",
  deleteService: "deleteService",
  addService: "addService",
  getServiceDetails: "getServiceDetails",
  editService: "editService",
  getWorkDetailsById: "getWorkDetails",
};

export default Urls;
