import React from "react";
import UserLayout from "../../../layout/userLayout";
import { Form, Button, Row, Col } from "react-bootstrap";
import appIcon from "../../../assets/icons/appLogo.png";
import Loader from "../../../components/loader";
import useLogin from "./util";

const LoginScreen = () => {
  const {
    loading,
    email,
    password,
    error,
    onChangeEmail,
    onChangePassword,
    onSubmit,
  } = useLogin();
  return (
    <UserLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="login-card">
          <div className="text-center">
            <img src={appIcon} width="80px" alt="logo" />
          </div>
          <h2 className="auth-title">Login</h2>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="login-label">Email address</Form.Label>
              <Form.Control
                className="app-input login-input"
                type="email"
                placeholder="example@email.com"
                value={email}
                onChange={onChangeEmail}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="login-label">Password</Form.Label>
              <Form.Control
                className="app-input login-input"
                type="password"
                placeholder="Password"
                value={password}
                onChange={onChangePassword}
              />
            </Form.Group>

            {error && (
              <div className="error-text pt-2">Invalid Email or Password</div>
            )}
            <div>
              <Button
                className="mt-4 auth-btn"
                variant="primary"
                type="submit"
                onClick={onSubmit}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      )}
    </UserLayout>
  );
};

export default LoginScreen;
