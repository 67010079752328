import Urls from "./Urls";
import { getData, postData } from "./Utils";

const userLogin = async (body) => {
  const res = await postData(`${Urls.loginUrl}`, body);
  return Promise.resolve(res);
};

const addContractor = async (body) => {
  const res = await postData(`${Urls.addContractor}`, body);
  return Promise.resolve(res);
};

const getContractors = async () => {
  const res = await getData(`${Urls.getContractors}`);
  return Promise.resolve(res);
};

const getContractorDetailsById = async (id) => {
  const res = await getData(`${Urls.getContractorDetailsById}/${id}`);
  return Promise.resolve(res);
};

const updateContractor = async (body, id) => {
  const res = await postData(`${Urls.updateContractor}/${id}`, body);
  return Promise.resolve(res);
};

const addClient = async (body) => {
  const res = await postData(`${Urls.addClient}`, body);
  return Promise.resolve(res);
};

const getClients = async () => {
  const res = await getData(`${Urls.getClients}`);
  return Promise.resolve(res);
};

const getClientDetailsById = async (id) => {
  const res = await getData(`${Urls.getClientDetailsById}/${id}`);
  return Promise.resolve(res);
};

const updateClient = async (body, id) => {
  const res = await postData(`${Urls.updateClient}/${id}`, body);
  return Promise.resolve(res);
};

const getWorks = async (body) => {
  const res = await postData(`${Urls.getWorks}`, body);
  return Promise.resolve(res);
};

const getContractorNames = async () => {
  const res = await getData(`${Urls.getContractorNames}`);
  return Promise.resolve(res);
};

const getClientNames = async () => {
  const res = await getData(`${Urls.getClientNames}`);
  return Promise.resolve(res);
};

const deleteContractorById = async (id) => {
  const res = await getData(`${Urls.deleteContractor}/${id}`);
  return Promise.resolve(res);
};

const deleteClientById = async (id) => {
  const res = await getData(`${Urls.deleteClient}/${id}`);
  return Promise.resolve(res);
};

const getServices = async () => {
  const res = await getData(`${Urls.getServices}`);
  return Promise.resolve(res);
};

const deleteServiceById = async (id) => {
  const res = await getData(`${Urls.deleteService}/${id}`);
  return Promise.resolve(res);
};

const addService = async (body) => {
  const res = await postData(`${Urls.addService}`, body);
  return Promise.resolve(res);
};

const getServiceDetailsById = async (id) => {
  const res = await getData(`${Urls.getServiceDetails}/${id}`);
  return Promise.resolve(res);
};

const updateService = async (body, id) => {
  const res = await postData(`${Urls.editService}/${id}`, body);
  return Promise.resolve(res);
};

const getWorkDetailsById = async (id) => {
  const res = await getData(`${Urls.getWorkDetailsById}/${id}`);
  return Promise.resolve(res);
};

export {
  userLogin,
  addContractor,
  getContractors,
  getContractorDetailsById,
  updateContractor,
  addClient,
  getClients,
  getClientDetailsById,
  updateClient,
  getWorks,
  getContractorNames,
  getClientNames,
  deleteContractorById,
  deleteClientById,
  getServices,
  deleteServiceById,
  addService,
  getServiceDetailsById,
  updateService,
  getWorkDetailsById,
};
